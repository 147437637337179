import Vue from 'vue'
import App from './App.vue'
import 'element-ui/lib/theme-chalk/index.css';

import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'swiper/dist/css/swiper.css'
import VueAwesomeSwiper from 'vue-awesome-swiper';
import Directives from '@/directives'
Vue.use(Directives)

import * as directive from './utils/directive'
// 注册全局的指令
Object.keys(directive).forEach((key) => {
  Vue.directive(key, directive[key])
})

import VueBus from 'vue-bus'
Vue.use(VueBus)

if (process.env.NODE_ENV === 'production') {
  if (window) {
    window.console.log = function () { }
  }
}

import * as vConsole from 'vconsole'
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI);

new Vue({
  router,
  store,
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  },
  render: h => h(App),
}).$mount('#app')
