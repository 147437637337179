/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-03-26 12:33:53
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-03-26 15:11:43
 * @FilePath: \chatgpt\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',

    component: () => import(/* webpackChunkName: "about" */ '../pages/index.vue'),

    //   children: [
    //         {
    //   path: 'index',
    //       name: 'Index',

    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
    // },

    //   ]
  },
  {
    path: '/ai',
    name: 'AI',
    redirect: "/freetalk/:id",
    component: () => import(/* webpackChunkName: "about" */ '../pages/ai/index.vue'),
    children: [
      {
        path: 'freetalk',
        name: 'FreeTalk',
        component: () => import(/* webpackChunkName: "about" */ '../pages/ai/freetalk.vue'),
      },
      {
        path: 'copywriting',
        name: 'Copywriting',
        component: () => import(/* webpackChunkName: "about" */ '../pages/ai/copywriting.vue'),
      },
      {
        path: 'line',
        name: 'SettingOutline',
        component: () => import(/* webpackChunkName: "about" */ '../pages/ai/settingOutline.vue'),
      },
      {
        path: 'wgrs',
        name: 'WGRS',
        component: () => import(/* webpackChunkName: "about" */ '../pages/ai/wgrs.vue'),
      },
      {
        path: 'summarize',
        name: 'Summarize',
        component: () => import(/* webpackChunkName: "about" */ '../pages/ai/summarize.vue'),
      },
    ],
  },
  {
    path: '/aidraw',
    name: 'AIDraw',
    redirect: "/quickdraw",
    component: () => import(/* webpackChunkName: "about" */ '../pages/aiDraw/index.vue'),
    children: [
      {
        path: 'quickdraw',
        name: 'QuickDraw',
        component: () => import(/* webpackChunkName: "about" */ '../pages/aiDraw/quickdraw.vue'),
      },
      {
        path: 'face',
        name: 'Face',
        component: () => import(/* webpackChunkName: "about" */ '../pages/aiDraw/face.vue'),
      },
      {
        path: 'fusion',
        name: 'Fusion',
        component: () => import(/* webpackChunkName: "about" */ '../pages/aiDraw/fusion.vue'),
      },
      {
        path: 'word',
        name: 'Word',
        component: () => import(/* webpackChunkName: "about" */ '../pages/aiDraw/word.vue'),
      },
    ],
  },
  {
    path: '/toolbox',
    name: 'Toolbox',
    redirect: "/black",
    component: () => import(/* webpackChunkName: "about" */ '../pages/toolbox/index.vue'),
    children: [
      {
        path: 'black',
        name: 'Black',
        component: () => import(/* webpackChunkName: "about" */ '../pages/toolbox/black.vue'),
      },
      {
        path: 'stretch',
        name: 'Stretch',
        component: () => import(/* webpackChunkName: "about" */ '../pages/toolbox/stretch.vue'),
      },
      {
        path: 'enlarge',
        name: 'Enlarge',
        component: () => import(/* webpackChunkName: "about" */ '../pages/toolbox/enlarge.vue'),
      },
      {
        path: 'doc',
        name: 'Doc',
        component: () => import(/* webpackChunkName: "about" */ '../pages/toolbox/doc.vue'),
      },
      {
        path: 'ppt',
        name: 'PPT',
        component: () => import(/* webpackChunkName: "about" */ '../pages/toolbox/ppt.vue'),
      },
      {
        path: 'pdf',
        name: 'PDF',
        component: () => import(/* webpackChunkName: "about" */ '../pages/toolbox/picToPdf.vue'),
      },
      {
        path: 'compress',
        name: 'Compress',
        component: () => import(/* webpackChunkName: "about" */ '../pages/toolbox/compress.vue'),
      },
      {
        path: 'comic',
        name: 'Comic',
        component: () => import(/* webpackChunkName: "about" */ '../pages/toolbox/comic.vue'),
      },
      {
        path: 'moire',
        name: 'Moire',
        component: () => import(/* webpackChunkName: "about" */ '../pages/toolbox/moire.vue'),
      },
      {
        path: 'repair',
        name: 'Repair',
        component: () => import(/* webpackChunkName: "about" */ '../pages/toolbox/repair.vue'),
      },
      {
        path: 'enhance',
        name: 'Enhance',
        component: () => import(/* webpackChunkName: "about" */ '../pages/toolbox/enhance.vue'),
      },
    ],
  },
  {
    path: '/member',
    name: 'Menber',
    component: () => import(/* webpackChunkName: "about" */ '../pages/user/member.vue'),
  },
  {
    path: '/user',
    name: 'User',
    redirect: "/userinfo",
    component: () => import(/* webpackChunkName: "about" */ '../pages/user/index.vue'),
    children: [
      {
        path: 'userinfo',
        name: 'UserInfo',
        component: () => import(/* webpackChunkName: "about" */ '../pages/user/userinfo.vue'),
      },
      {
        path: 'invite',
        name: 'Invite',
        component: () => import(/* webpackChunkName: "about" */ '../pages/user/invite.vue'),
      },
      {
        path: 'order',
        name: 'Order',
        component: () => import(/* webpackChunkName: "about" */ '../pages/user/order.vue'),
      },
      {
        path: 'history',
        name: 'History',
        component: () => import(/* webpackChunkName: "about" */ '../pages/user/history.vue'),
      },
      {
        path: 'public',
        name: 'Public',
        component: () => import(/* webpackChunkName: "about" */ '../pages/user/public.vue'),
      },
    ],
  },
  {
    path: '/privacy',
    name: 'Privacy',
    redirect: "/privacy/policies",
    component: () => import(/* webpackChunkName: "about" */ '../pages/privacy/index.vue'),
    children: [
      {
        path: 'policies',
        name: 'Policies',
        component: () => import(/* webpackChunkName: "about" */ '../pages/privacy/policies.vue'),
      },
      {
        path: 'agreements',
        name: 'Agreements',
        component: () => import(/* webpackChunkName: "about" */ '../pages/privacy/agreements.vue'),
      },

    ],
  },
  {
    path: '/interactDetail',
    name: 'InteractDetail',
    component: () => import(/* webpackChunkName: "about" */ '../pages/interactDetail.vue'),
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, form, next) => {
  // console.log(form)
  // console.log(to)
  if (to.path == '/out') {
    store.commit('SET_VISIBLE', true)
  }
  else if (to.path == '/privacy/agreements' || to.path == '/privacy/policies') {
    store.commit('SET_LOGINDISLOG', false)
    next()
  }
  else {
    next()
  }
})
export default router
