<template>
  <el-dialog
    class="out"
    :visible.sync="$store.state.outVisible"
    :width="$store.state.isMobile ? '343px' : '418px'"
    title="提示消息"
    :before-close="handleClose"
    :show-close="closeDialog"
  >
    <span class="text">功能加急上线中，敬请期待~</span>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <div
        class="button"
        @click=" handleClose"
      >知道了</div>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: true,
      closeDialog: false,
    };
  },
  methods: {
    handleClose() {
        this.$store.commit('SET_VISIBLE',false)
    },
  },
};
</script>
<style lang='less'>
.out {
  .el-dialog {
    border-radius: 24px !important;
    padding: 24px;
    .el-dialog__header {
      text-align: center;
      font-family: Source Han Sans CN;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0em;

      /* Grey/10 */
      color: #1f2126;
    }
    .text {
      display: block;
      text-align: center;
      font-family: Source Han Sans CN;
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0em;

      /* Grey/9 */
      color: #373940;
    }
     .el-dialog__footer{
            padding:0px !important;
        }
    .dialog-footer{
       
        .button{
            width: 106px;
height: 32px;
border-radius: 6px;
opacity: 1;
margin:0  auto;
cursor: pointer;
/* 自动布局 */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 5px 32px;
gap: 8px;

/* Main/06 */
background: #1467FF;

/* 按钮/主要 */
box-shadow: 0px 1.5px 0px 0px #E6EAF0;
font-family: Source Han Sans CN;
font-size: 14px;
font-weight: normal;
line-height: 22px;
letter-spacing: 0em;

/* Grey/0 */
color: #FFFFFF;
        }
    }
    
  }
}
</style>