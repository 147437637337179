<template>
  <el-dialog
    title=" "
    :visible.sync="$store.state.loginDialog"
    :width="!$store.state.isMobile ? '480px' : '353px'"
    :show-close="closeDialog"
    :before-close="handleClose"
  >
    <div v-if="!$store.state.isMobile">
      <span slot="header" />
      <div class="content">
        <p class="title">
          <img src="@/static/index/wechat-fill.png">微信扫码登录
        </p>
        <p class="text2">
          扫码后将自动注册账号，新用户将免费赠送 <span style="color: #FF9837;">5 金豆</span>
        </p>
        <div class="code">
          <div class="img">
            <!-- <img :src="stateCode" /> -->
            <el-image :src="stateCode">
              <div
                slot="placeholder"
                class="image-slot"
              >
                加载中<span class="dot">...</span>
              </div>
            </el-image>
            <div
              v-show="isLogin ==true"
              class="code1"
            >
              <img src="@/static/index/suc.png">
              <p>登录成功</p>
            </div>
          </div>
        </div>
        <p
          class="text"
          :style="{ marginTop: isLogin != true ? '76px' : '' }"
        >
          <span v-show="isLogin==true">即将自动关闭</span>
          关注公众号后自动注册账号，并默认同意<router-link
            to="/privacy/policies"
          >
            隐私政策
          </router-link>及<router-link to="/privacy/agreements">
            用户协议
          </router-link>
        </p>
      </div>
    </div>
    <!-- <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span> -->
  </el-dialog>
</template>

<script>
export default {
  props: {
    // visible: {
    //   type: Boolean,
    //   default: false,
    // },
    code: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      closeDialog: false,
    };
  },
  computed: {
    stateCode() {
      return this.$store.state.code;
    },
    isLogin() {
        
      return this.$store.state.isLogin;
    },
  
  },
  watch: {
    isLogin(value) {
      setTimeout(() => {
        if (value) {
          this.handleClose();  
        }
    
      }, 1000);
    },
  
    '$store.state.loginDialog'(value){
      if(value){
        this.$store.dispatch("getCode");
        this.timer = setInterval(() => {
          this.$store.dispatch("CheckLogin").then(() => {
            if(this.$store.state.isLogin) {
              this.$message({
                message: "您已成功登录",
                type: "success",
                duration: 1000,
              })
              window.location.reload()
            }
          });
        }, 3000);
      }
    }
  },
  
 
  methods: {
    handleClose() {
     this.$store.commit('SET_LOGINDISLOG', false)
      this.$emit("close", false);
       clearInterval(this.timer);
    },
  },
};
</script>

<style lang='less'>
.content {
  .title {
    img {
      width: 36px;
      height: 36px;
      margin-right: 12px;
    }
    font-family: Source Han Sans CN;
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;

    /* Grey/10 */
    color: #1f2126;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text2{
    font-size: 14px;
    color: #3D3D3D;
    text-align: center;
    margin-top: 27px;
  }
  .code {
    position: relative;
    /* 自动布局 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 16px;
    box-sizing: border-box;
    /* Grey/0 */
    background: #ffffff;

    box-sizing: border-box;
    /* Grey/1.5 */
    border: 1px solid #e6e9f0;

    /* 卡片阴影 */
    box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
    width: 300px;
    height: 300px;
    border-radius: 24px;
    margin: 0 auto;
    margin-top: 22px;
  }
  .text {
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0em;
    span {
      width: 100%;
      display: inline-block;
      margin: 27px 0px;
      /* Body/regular */
      font-family: Source Han Sans CN;
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
      letter-spacing: 0em;

      /* Grey/10 */
      color: #1f2126;
    }

    /* Grey/4 */
    color: #acb1bf;
    a {
      text-decoration: underline;
      color: #acb1bf;
    }
  }
  .img {
    position: relative;
    img {
      width: 250px;
      height: 250px;
    }
    .code1 {
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 24px;
      flex-direction: column;
      img {
        height: 48px;
        width: 48px;
      }
      p {
        font-family: Source Han Sans CN;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;

        /* Grey/0 */
        color: #ffffff;
        margin-top: 12px;
      }
    }
  }
}
</style>