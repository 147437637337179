import axios from 'axios'
import { Message } from 'element-ui'
// import store from "@/store/index"
let request = axios.create({
  // 基础路径
  baseURL: '',//基础路径会携带api
  timeout: 5000,//超时的时间的设置

})
// 添加请求响应拦截器
request.interceptors.request.use((config) => {
  // config 配置对象，header属性，比如要给服务端的公共参数
  // 返回配置对象

  //   console.log(config)
  return config;
})
request.interceptors.response.use((response) => {
  return response.data
}, (error) => {
  console.log(error.response.data)
  // 失败的回调，处理http的网络错误
  let message = '';
  console.log(message)
  // http状态码

  let status = error.response.status;
  console.log(status)
  switch (status) {
    case 401:
      message = "用户未登录"
      break;
    case 403:
      message = "无权访问"
      break;
    case 500:
      message = "服务器错误"
  }
  // if (status == 401) {
  //       Message({
  //       message: error.response.data.message,
  //       type: "error",
  //       customClass: "tiv",
  //       });
  //     // store.commit('SET_LOGINDISLOG', true)
  //     localStorage.clear()
  // }
  // if (status == 500) {
  //       Message({
  //       message: '服务器错误',
  //       type: "error",
  //       customClass: "tiv",
  //       });
  //     return
  // }
  if (status != 401) {
    if (status == 500) {
      Message({
        message: error.response.data.message,
        // message: "服务器错误",
        type: "error",
        customClass: "tiv",
      });
    } else {
      Message({
        message: error.response.data.message,
        type: "error",
        customClass: "tiv",
      });
    }
  }

  return Promise.reject(error)
})
export default request