<template>
  <header>
    <nav
      class="navDefault"
      :class="isChange == true ? 'navFixed' : ''"
    >
      <div style="display: flex; justify-content: flex-start">
        <a href="/"> <img
          src="@/static/logo.png"
          alt=""
        ></a>
        <ul
          v-if="!$store.state.isMobile"
          class="nav"
        >
          <li>
            <router-link to="/">
              首页
            </router-link>
          </li>
          <li class="navLi">
            <span>AI文字<img src="@/static/index/arrow.png"></span>
            <ul class="navList">
              <li>
                <router-link
                  to="/ai/freetalk"
                >
                  <div class="navImg">
                    <img src="@/static/index/ai-icon1.png">
                  </div>
                  <div class="navItem">
                    <p class="title">
                      自由对话
                    </p>
                    <p class="text">
                      减少空间占用
                    </p>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/ai/copywriting"
                >
                  <div class="navImg">
                    <img src="@/static/index/ai-icon2.png">
                  </div>
                  <div class="navItem">
                    <p class="title">
                      文案撰写
                    </p>
                    <p class="text">
                      快速完成一篇文案
                    </p>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/ai/line"
                >
                  <div class="navImg">
                    <img src="@/static/index/ai-icon3.png">
                  </div>
                  <div class="navItem">
                    <p class="title">
                      拟定提纲
                    </p>
                    <p class="text">
                      为主题构建提纲目录
                    </p>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/ai/wgrs"
                >
                  <div class="navImg">
                    <img src="@/static/index/ai-icon4.png">
                  </div>
                  <div class="navItem">
                    <p class="title">
                      文稿润色 & 纠错
                    </p>
                    <p class="text">
                      提升文稿的文学水平
                    </p>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/ai/summarize"
                >
                  <div class="navImg">
                    <img src="@/static/index/ai-icon5.png">
                  </div>
                  <div class="navItem">
                    <p class="title">
                      内容总结
                    </p>
                    <p class="text">
                      梳理文章内容要点
                    </p>
                  </div>
                </router-link>
              </li>
            </ul>
          </li>
          <li class="navLi">
            <span>AI绘画<img src="@/static/index/arrow.png"></span>
            <ul class="navList">
              <li>
                <router-link
                  to="/aidraw/quickdraw"
                >
                  <div class="navImg">
                    <img src="@/static/index/ai-icon6.png">
                  </div>
                  <div class="navItem">
                    <p class="title">
                      快速绘画
                    </p>
                    <p class="text">
                      尽情创作艺术作品
                    </p>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/aidraw/word"
                >
                  <div class="navImg">
                    <img src="@/static/ai/word.png">
                  </div>
                  <div class="navItem">
                    <p class="title">
                      图生文
                    </p>
                    <p class="text">
                      将图片转换为描述文字
                    </p>
                  </div>
                </router-link>
              </li>
              <!-- <li>
                <router-link
                  to="/aidraw/fusion"
                >
                  <div class="navImg">
                    <img src="@/static/ai/fusion.png">
                  </div>
                  <div class="navItem">
                    <p class="title">
                      图像融合
                    </p>
                    <p class="text" />
                  </div>
                </router-link>
              </li> -->
              <li>
                <router-link
                  to="/aidraw/face"
                >
                  <div class="navImg">
                    <img src="@/static/ai/face.png">
                  </div>
                  <div class="navItem">
                    <p class="title">
                      AI换脸
                    </p>
                    <p class="text">
                      制作有趣的人脸替换
                    </p>
                  </div>
                </router-link>
              </li>
            </ul>
          </li>
          <li class="navLi">
            <router-link to="/out">
              AI技能
            </router-link>
          </li>
          <li class="navLi">
            <span>工具箱<img src="@/static/index/arrow.png"></span>
            <ul class="navList navList2">
              <li>
                <router-link
                  to="/toolbox/black"
                >
                  <div class="navImg">
                    <img src="@/static/ai/black.png">
                  </div>
                  <div class="navItem">
                    <p class="title">
                      黑白图像上色
                    </p>
                    <p class="text">
                      为黑白图像增添色彩
                    </p>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/toolbox/repair"
                >
                  <div class="navImg">
                    <img src="@/static/ai/repair.png">
                  </div>
                  <div class="navItem">
                    <p class="title">
                      文档图片去底纹
                    </p>
                    <p class="text" />
                  </div>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/toolbox/stretch"
                >
                  <div class="navImg">
                    <img src="@/static/ai/stretch.png">
                  </div>
                  <div class="navItem">
                    <p class="title">
                      拉伸图像恢复
                    </p>
                    <p class="text">
                      恢复图像正常比例
                    </p>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/toolbox/enhance"
                >
                  <div class="navImg">
                    <img src="@/static/ai/enhance.png">
                  </div>
                  <div class="navItem">
                    <p class="title">
                      图片清晰度增强
                    </p>
                    <p class="text" />
                  </div>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/toolbox/enlarge"
                >
                  <div class="navImg">
                    <img src="@/static/ai/enlarge.png">
                  </div>
                  <div class="navItem">
                    <p class="title">
                      图像无损放大
                    </p>
                    <p class="text">
                      无损提高图像分辨率
                    </p>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/toolbox/pdf"
                >
                  <div class="navImg">
                    <img src="@/static/ai/pdf2.png">
                  </div>
                  <div class="navItem">
                    <p class="title">
                      图片转PDF
                    </p>
                    <p class="text">
                      一键转换图片至 PDF
                    </p>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/toolbox/compress"
                >
                  <div class="navImg">
                    <img src="@/static/ai/compress.png">
                  </div>
                  <div class="navItem">
                    <p class="title">
                      图片压缩
                    </p>
                    <p class="text">
                      减少图片空间占用
                    </p>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/toolbox/doc"
                >
                  <div class="navImg">
                    <img src="@/static/ai/doc.png">
                  </div>
                  <div class="navItem">
                    <p class="title">
                      PDF转Word
                    </p>
                    <p class="text">
                      一键转换PDF至Word
                    </p>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/toolbox/moire"
                >
                  <div class="navImg">
                    <img src="@/static/ai/moire.png">
                  </div>
                  <div class="navItem">
                    <p class="title">
                      去摩尔纹
                    </p>
                    <p class="text" />
                  </div>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/toolbox/ppt"
                >
                  <div class="navImg">
                    <img src="@/static/ai/ppt.png">
                  </div>
                  <div class="navItem">
                    <p class="title">
                      PDF转PPT
                    </p>
                    <p class="text">
                      一键转换PDF至PPT
                    </p>
                  </div>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div style="display: flex;align-items: center;">
        <div
          class="to_mobile"
          @click="handleToMobile"
        >
          <img
            src="@/static/index/mobile.png"
            alt=""
          >
          <span>访问移动端</span>
        </div>
        <div
          v-if="$store.state.isLogin"
          class="logined"
          :style="{width : !$store.state.isMobile ? '254px' : 'auto'}"
        >
          <router-link
            class="item1"
            to="/user/userinfo"
          >
            <img src="@/static/index/money.png">
            {{ $store.state.userInfo.sysCurrencyNum }}
          </router-link>
          <router-link
            v-if="$store.state.userInfo.userType == 'VIP0'"
            class="item2"
            to="/member"
          >
            <img src="@/static/index/vip.png">
          </router-link>
          <router-link
            class="item4"
            to="/user/userinfo"
          >
            <img
              v-if="$store.state.userInfo.userType == 'VIP8'"
              src="@/static/index/forever.png"
            >
            <img
              v-if="$store.state.userInfo.userType == 'VIP7'"
              src="@/static/index/year.png"
            >
            <img
              v-if="$store.state.userInfo.userType == 'VIP6'"
              src="@/static/index/month.png"
            >
          </router-link>
          <div class="item3">
            <el-popover
              placement="top-start"
              popper-class="login-pop"
              trigger="hover"
            >
              <template #reference>
                <img
                  src="@/static/index/default.png"
                  alt=""
                >
              </template>
              <ul class="list">
                <li>
                  <router-link to="/user/userinfo">
                    个人中心
                  </router-link>
                </li>
                <li><span @click="LogOut">退出登录</span></li>
              </ul>
            </el-popover>
          </div>
        </div>
        <div
          v-else
          class="unlisted"
          @click="showDialog()"
        >
          <img src="@/static/index/wechat-fill.png"> 微信登录
        </div>
      </div>
    </nav>
    <el-dialog
      :visible.sync="mobileVisible"
      width="396px"
      :before-close="handleClose"
    >
      <div class="mobile_dialog">
        <div class="dia_title">
          <img
            src="@/static/index/new.png"
            alt=""
          >
        </div>
        <div class="mobile_code">
          <img
            src="@/assets/index/urlCode.png"
            alt=""
          >
        </div>
        <p>打开微信扫描上方二维码，或在“五点下班”公众号，点击【开启创作】即可使用</p>
      </div>
    </el-dialog>
    <login-dialog
      key="new Date()"
      :visible="visible"
      @close="close"
    />
  </header>
</template>

<script>
import LoginDialog from "./LoginDialog";
import { logout } from "@/api/index";

export default {
  name: "NavDefault",
  components: {
    LoginDialog,
  },
  data() {
    return {
      isChange: false,
      visible: false,
      code: "",
      innerWidth: '',
      showMenu: false,
      mobileVisible: false
    };
  },
  watch: {
    $route() {
      this.showMenu = false
    },
  },
  mounted() {
    this.changeNav();
    window.addEventListener("scroll", this.changeNav)
  },
  methods: {
    handleClose() {
      this.mobileVisible = false
    },
    handleToMobile() {
      this.mobileVisible = true
    },
    async LogOut() {
      this.$router.push("/");
      localStorage.clear();
      location.reload();
      await logout();
    },
    changeNav() {
      const t = document.documentElement.scrollTop || document.body.scrollTop;

      if (t > 20) {
        this.isChange = true;
      } else {
        this.isChange = false;
      }
    },

    close(value) {
      this.visible = value;
    },
    showDialog() {


       this.$store.commit('SET_LOGINDISLOG', true)
    },
  },
};
</script>

<style lang="less">
.navDefault {
  position: fixed;
  width: 100%;
  min-width: 1264px;
  height: 56px;
  display: flex;
  padding: 7px 60px;
  box-sizing: border-box;
  background: #fff;
  justify-content: space-between;
  z-index: 100;
  &.navFixed {
    position: fixed;
    z-index: 100;
    box-shadow: 0px 50px 60px 0px rgba(0, 0, 0, 0.08);
  }
  .to_mobile{
    width: 108px;
    height: 36px;
    border-radius: 999px;
    display: flex;
    align-items: center;
    padding: 6px 12px;
    background: #F2F4F7;
    box-sizing: border-box;
    margin-right: 40px;
    cursor: pointer;
    img{
      width: 8px;
      height: 13px;
      margin-right: 6px;
    }
    span{
      font-size: 14px;
      font-weight: 500;
      color: #1F2126;
      white-space: nowrap;
    }
  }
  .menu{
    position: absolute;
    width: 24px;
    height: 24px;
    top: 15px;
    left: 16px;
    z-index: 101;
    img{
    width: 24px;
    height: 24px;

    }
  }
  img {
    width: 145px;
    height: 40px;
    margin-right: 16px;
  }
  .nav {
    padding: 7px 0px;
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    margin: 0px;
    li {
      display: flex;
      align-items: center;
      height: 42px;
      line-height: 42px;

      margin: 0 32px;
      position: relative;
      cursor: pointer;

      font-size: 14px;
      font-weight: 600;

      a {
        color: #1f2126;
        text-decoration: none;
        font-size: 14px;
        font-weight: 600;
        white-space: nowrap;
      }
      span {
        padding: 0px 18px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        /* width: 108px; */
        box-sizing: border-box;
        border-radius: 8px;
        white-space: nowrap;
      }
      &:hover {
        span {
          background: #f2f4f7;
        }
      }
      &.navLi {
        height: 68px;
 width: 94px;
        img {
          width: 8px;
          height: 4px;
          margin-left: 8px;
          margin-right: 0px;
        }
        .navList {
          position: absolute;
          left: 0;
          display: none;
          top: 68px;
          height: 0;
          background: #ffffff;
          z-index: 100;
          gap: 8px;
          float: left;
          border-radius: 16px;
          box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.15);
          padding: 16px;
          box-sizing: border-box;
          li {
            width: 216px;
            display: inline-block;
            padding: 0;
            margin: 0;
            margin-bottom: 8px;
            height: 70px;
            box-sizing: border-box;
            white-space: wrap;
            &:last-child {
              margin-bottom: 0px;
            }
            a {
              /* 自动布局 */
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 8px;
              gap: 12px;
              height: 70px;
              border-radius: 8px;
              box-sizing: border-box;
              width: 100%;
              white-space: wrap;


              .navImg {
                width: 45px;
                height: 45px;
                border-radius: 6px;
                display: flex;
                justify-content: center;
                text-align: center;
                align-items: center;
                border-radius: 6px;
               background: #F8F9FC;
                img {
                  width: 32px;
                  height: 32px;
                  margin-left: 0;
                  margin: 0 auto;
                }
              }
               &:hover {
                background: #f2f4f7;
                .navImg{
                  background: #fff;
                }
              }
              .navItem {
                .title {
                  font-family: Source Han Sans CN;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 24px;
                  letter-spacing: 0em;

                  /* Grey/9 */
                  color: #373940;
                }
                .text {
                  padding-top: 8px;
                  /* Body/regular */
                  font-family: Source Han Sans CN;
                  font-size: 14px;
                  font-weight: normal;
                  line-height: 22px;
                  letter-spacing: 0em;

                  /* Grey/6 */
                  color: #7a7f8c;
                }
              }
            }
          }
        }
        .navList2{
          width: 472px;
          height: 414px;
        }
        &:hover {
          .navList {
            height: auto;
            max-height: 414px;
            display: block;
          }
        }
      }
    }
  }
  .unlisted {
    width: 120px;
    height: 40px;
    border-radius: 399px;
    opacity: 1;
    cursor: pointer;
    /* 自动布局 */
    display: flex;

    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    padding: 8px 16px;

    font-family: Source Han Sans CN;
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    color: #646873;

    /* Grey/1 */
    background: #f2f4f7;
    white-space: nowrap;
    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
  .logined {
    width: 254px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .item1 {
      width: 84px;
      height: 36px;
      border-radius: 999px;
      text-decoration: none;
      opacity: 1;

      /* 自动布局 */
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 6px 8px;
      box-sizing: border-box;
      /* Grey/1 */
      background: #f2f4f7;
      font-family: Source Han Sans CN;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0em;

      color: #3d3d3d;
      margin-right: 25px;
      img {
        width: 24px;
        height: 24px;
        margin-right: 0px;
      }
    }
    .item2 {
      width: 86px;
      display: flex;
      margin-right: 25px;
      img {
        width: 86px;
        height: auto;
        margin-right: 0px;
      }
    }
    .item3 {
      img {
        width: 36px;
        height: 36px;
        border-radius: 50% 50%;
        margin-right: 0;
      }
      .el-popover__reference-wrapper img{
          width: 36px !important;
        height: 36px !important;
      }
    }
    .item4{
      display: flex;
      align-items: center;
      img{
        width: 86px;
        height: auto;
      }
    }
  }
}
.mobile_dialog{
  text-align: center;
  padding: 0 27px;
  box-sizing: border-box;
  height: 332px;
  .dia_title{
    margin: 16px 0 28px;
    img{
      width: 224px;
      height: 36px;
    }
  }
  .mobile_code{
    width: 181px;
    height: 181px;
    border-radius: 15px;
    padding: 5px;
    box-sizing: border-box;
    background: #fff;
    margin: 16px auto;
    img{
      width: 100%;
      height: 100%;
    }
  }
  p{
    font-size: 13px;
    line-height: 21px;
    color: #646873;
  }
}
.el-dialog{
  background: linear-gradient(156deg, #EDEFFA 0%, #FAFAFB 71%) !important;
}
.el-dialog__body{
  padding: 32px 16px 32px 24px !important;
}
.login-pop {
  min-width: 104px !important;
  height: 84px !important;
  border-radius: 12px !important;
  box-sizing: border-box;
  opacity: 1;

  /* 自动布局 */
  display: flex;
  flex-direction: column;

  padding: 6px !important;
  gap: 4px;

  /* Grey/0 */
  background: #ffffff;

  box-shadow: 0px 12px 35px 0px rgba(0, 0, 0, 0.2);
  .list {
    padding-left: 0;
    li {
      list-style: none;
      width: 88px;

      border-radius: 6px;
      opacity: 1;
      justify-content: center;
      /* 自动布局 */
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 34px;
      gap: 8px;

      /* Grey/1 */

      a,
      span {
        /* Body/regular */
        font-family: Source Han Sans CN;
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
        letter-spacing: 0em;

        /* Grey/10 */
        color: #1f2126;
        text-decoration: none;
      }
      &:hover {
        background: #f2f4f7;
      }
    }
  }
}
.phone_nav{
  width: 100%;
  position: fixed;
  top: 56px;
  border-top: 1px solid #eee;
  z-index: 100;
  height: calc(100vh - 56px);
  background: rgba(0, 0, 0, 0.5);
  .nav{
    height: 270px;
    border-radius: 0px 0px 12px 12px;
    padding: 32px 24px;
    background: #FFFFFF;
    box-sizing: border-box;
    ul{
      li{
        list-style: none;
        margin: 24px 0;
        height: 22px;
        a{
          text-decoration: none;
          font-size: 16px;
          color: #1F2126;
          font-family: PingFangSCBold-Bold;
        }
      }
      li:nth-of-type(1){
        font-size: 14px;
        color: #ACB1BF;
        margin: 0 0 16px 0;
      }
    }
  }
}
</style>

