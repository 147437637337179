import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import { reqLogin, checkLogin, getUserInfo, chatVersions, listChatRoom, listChatMessage, chargerule } from '@/api/index'
import { getLoginCode, setLoginCode } from '@/utils/auth'
// import { Message } from 'element-ui';
const store = new Vuex.Store({
  state: {
    code: '', // 登录二维码
    isLogin: getLoginCode() || false, //是否登录
    loginDialog: false,
    // 登录信息
    userInfo: {
      sysCurrencyNum: '',
      nickname: ''
    },
    // 版本信息
    versions: [{
      code: '',
      desc: ''
    }],
    version: '',
    outVisible: false,
    listChatRoom: [], //对话记录
    listChatMessage: [], //对话内容
    showUid: localStorage.getItem('uid') || '',
    chargerule: '',
    searchIndex: true,
    sccessDialogVisible: false,
    isMobile: false,
    baiduPopularizeVid: '',
  },
  mutations: {
    SET_BAIDUPOPULARIZEVID: (state, data) => {
      state.baiduPopularizeVid = data
    },
    SET_SCCESSDIALOGVISIBLE: (state, data) => {
      state.sccessDialogVisible = data
    },
    SET_SEARCHINDEX: (state, data) => {
      state.searchIndex = data
    },
    SET_CODE: (state, code) => {
      state.code = code
    },
    SET_LOGINDISLOG: (state, loginDialog) => {
      state.loginDialog = loginDialog

    },

    SET_ISLOGIN: (state, isLogin) => {
      state.isLogin = isLogin
      setLoginCode(isLogin)
    },
    SET_USERINFO: (state, data) => {
      state.userInfo = data
    },
    SET_VERSIONS: (state, data) => {
      state.versions = data
    },
    SET_VISIBLE: (state, data) => {
      state.outVisible = data
    },

    SET_VERSION: (state, data) => {
      state.version = data
    },
    SET_LISTCHATROOM: (state, data) => {
      state.listChatRoom = data
    },
    SET_LISTCHATROOMMESSAGE: (state, data) => {
      state.listChatMessage = data
    },
    SET_SHOWUID (state, data) {
      state.showUid = data
    },
    SET_CHARGERULE (state, data) {
      state.chargerule = data
    },
    SET_INNERWIDTH (state, data) {
      state.isMobile = data
    },
  },
  actions: {
    getCode ({ commit }) {
      return new Promise((resolve, reject) => {
        reqLogin().then(res => {
          commit('SET_CODE', res.data.ssoQrCodeUrl)


        }).catch(error => {
          reject(error)
        })
      })
    },
    // 
    CheckLogin ({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        checkLogin().then(res => {
          if (res.data == true) {
            // Message({
            //   message: "您已成功登录",
            //   type: "success",
            //   duration: 1000,
            //   customClass: "tiv",
            // });
            dispatch('GetUserInfo')
            commit('SET_ISLOGIN', res.data)
          } else {
            localStorage.clear()
          }

          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetUserInfo ({ commit }) {
      return new Promise((resolve, reject) => {

        getUserInfo().then(res => {
          commit('SET_USERINFO', res.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    getVersions ({ commit }) {
      return new Promise((resolve, reject) => {
        chatVersions().then(res => {
          commit('SET_VERSIONS', res.data)
          commit('SET_VERSION', res.data[0].code)
          //  dispatch('getChargerule')
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    getChatList ({ commit }) {

      return new Promise((resolve, reject) => {
        const listChat = []
        listChatRoom().then(res => {
          res.data.forEach(element => {
            element.edit = false
            element.text = element.roomTitle
            listChat.push(element)
          });
          listChat.reverse()
          commit('SET_LISTCHATROOM', listChat)
          //    if (listChat.length > 0) {
          //         dispatch('getListChatMessage',{roomUid:listChat[0].uid})
          //    }

          resolve()
        }).catch(error => {
          reject(error)
        })

      })

    },
    getListChatMessage ({ commit }, data) {

      return new Promise((resolve, reject) => {
        console.log(data)
        listChatMessage(data).then(res => {
          commit('SET_LISTCHATROOMMESSAGE', res.data)

          resolve()

        }).catch(error => {
          reject(error)
        })

      })
    },
    getChargerule ({ commit, state }, browsingBl) {
      const data = {
        accessType: "AI_CHAR",
        apiKey: state.version,
        // browsingBl: browsingBl
      };
      chargerule(data).then((res) => {
        commit('SET_CHARGERULE', res.data)
      });
    },


  }
})

export default store