<template>
  <div class="foot">
    <div class="box">
      <div class="foot-left">
        <div class="text">
          五点下班——点燃创造力，释放生产力
        </div>
        <div class="img">
          <img src="@/static/index/image.png">
        </div>
      </div>
      <div class="foot-right">
        <div class="item">
          <div class="title">
            导航
          </div>
          <ul>
            <li>
              <a href="/">首页</a>
            </li>
            <li>
              <router-link to="/ai/freetalk">
                AI文字
              </router-link>
            </li>
            <li>
              <router-link to="/aidraw/quickdraw">
                AI绘画
              </router-link>
            </li>
            <li>
              <router-link to="/out">
                AI技能
              </router-link>
            </li>
            <li>
              <router-link to="/toolbox/compress">
                工具箱
              </router-link>
            </li>
          </ul>
        </div>
        <div class="item">
          <div class="title">
            关于五点下班
          </div>
          <ul>
            <li>
              <span @click="scroll">关于我们</span>
            </li>
            <li>
              <router-link to="/privacy/policies">
                隐私政策
              </router-link>
            </li>
            <li>
              <router-link to="/privacy/agreements">
                用户协议
              </router-link>
            </li>
          </ul>
        </div>
        <div class="item">
          <div class="title">
            联系我们
          </div>
          <ul>
            <li>
              <span>wudianxiaban_aigc@163.com</span>
            </li>
            <li>
              <span>创作者微信交流群：Teacher_AIGC</span>
            </li>
            <li>
              <img
                style="width:80px;height:80px; border-radius:8px;"
                src="@/assets/index/code.jpg"
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="copy">
      Copyright ©2023 五点下班 保留所有权利 <a
        target="_blank"
        href="https://beian.miit.gov.cn/#/Integrated/index"
      >蜀ICP备2023007406号-2</a> 成都咚佳旭科技有限公司
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterDefault',
  methods:{
    scroll(){
       window.scrollTo({
  'top':1650,
  'behavior': 'smooth'
  })
    }
  }
}
</script>

<style lang="less">
  .foot {
      width: 100%;
      padding-top:80px;
    .box {
        width: 1264px;
      margin: 0 auto;
      padding:0 32px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      .foot-left {
          margin: 0px 113px; 
        .text {
          /* Head/medium */
          font-family: Source Han Sans CN;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
  
          /* Grey/10 */
          color: #1f2126;
        }
        .img {
          margin-top:58px;
         img{
           width: 86px;
          height: 86px;
         }
        }
      }
      .foot-right{
          display: flex;
          justify-content: space-between;
         .item{
          margin:0 60px;
          .title{
              /* Head/medium */
  font-family: Source Han Sans CN;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  white-space: nowrap;
  /* Grey/10 */
  color: #1F2126;
  padding-bottom:20px;
          }
           ul{
              padding-left: 0;
              li{
                  list-style: none;
                  span,a{
                    cursor: pointer;
                      text-decoration: none;
                      font-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0em;
  white-space: nowrap;
  /* Grey/7 */
  color: #646873;
  display: inline-block;
  padding:10px 0;
                  }
              }
          }
         }
      }
    }
    .copy{
      margin-top: 43px;
      text-align: center;
      height: 40px;
      font-family: Source Han Sans CN;
  font-size: 12px;
  font-weight: normal;
  line-height:40px;
  letter-spacing: 0em;
  
  /* Grey/6 */
  color: #7A7F8C;
  a{
    font-family: Source Han Sans CN;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: 0em;
  text-decoration: none;
  /* Grey/6 */
  color: #7A7F8C;
  margin-right: 12px;
  
  }
    }
  }
</style>