
export const getLoginCode =  ()=>{
  return localStorage.getItem("loginCode")
}

 
export function setLoginCode(loginCode) {
   
  localStorage.setItem('loginCode', loginCode)
  
}