/**
 * 滚动条处理
 */
/**
 * 函数防抖
 */
function debounce (fn, delay) {
  // 记录上一次的延时器
  let timer = null
  var d = delay || 200
  return function () {
    const args = arguments
    const that = this
    // 清除上一次延时器
    clearTimeout(timer)
    timer = setTimeout(function () {
      fn.apply(that, args)
    }, d)
  }
}

export const scrollActive = {
  bind (element, binding) {
  },
  inserted () {
    // console.log('inserted')
  },
  update () {
    // console.log('update')
  },
  componentUpdated (element, binding) {
    try {
      let el = element
      const selector = binding.value?.selector
      if (selector) {
        el = el.querySelector(selector) || el
      }
      const hideScroll = debounce((el) => {
        el.classList.add('dom-scrollbar-hide')
        el.classList.remove('dom-scrollbar-show')
      }, 1000)
      el.classList.add('dom-scrollbar-hide')
      el.addEventListener('scroll', function () {
        if (Array.from(el.classList).includes('dom-scrollbar-show')) {
          hideScroll(el)
          return
        }
        el.classList.add('dom-scrollbar-show')
        el.classList.remove('dom-scrollbar-hide')
      })
    } catch (e) {
      console.error('滚动条样式调整失败', e)
    }
  },
  unbind (element, binding) {
    let el = element
    if (el) {
      const selector = binding.value?.selector
      if (selector) {
        el = element.querySelector(selector) || element
      }
      el.removeEventListener('scroll', () => { })
    }
  }
}
