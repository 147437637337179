import request from "@/utils/request";
import axios from 'axios'
export const reqLogin = () => request.get('/login/qrcode')
export const checkLogin = () => request.get('/login/checkLogin')
// 获取用户信息接口
export const getUserInfo = () => request.get('/user/info')
export const logout = () => request.get('/logout')
// 查询AI文字对话历史记录接口
export const listChatRoom = () => request.get('/ai/chat/listChatRoom')
// 查询AI对话版本列表
export const chatVersions = () => request.get("/ai/chat/versions")
// AI-自由对话-发起提问接口
export const chatSend = (data) => request.post('/ai/chat/send/stream/base', data,
  {
    headers: {
      ' Accept': 'text/event-stream',
      responseType: 'stream'
    },
  },
)

// 新对话页面查询数据字典值 画面比例=AI_DRAW_BASE_ASPECTRATIOTYPE
export const listByGroup = (data) => request.get("/ai/common/listByGroup", { params: data })
// AI-文字-编辑对话房间标题
export const updateTitle = (data) => request.post("/ai/chat/updateTitle?" + `roomUid=${data.roomUid}&roomTitile=${data.roomTitle}`)
// 根据对话房间UID查询所有对话记录
export const listChatMessage = (data) => request.get("/ai/chat/listChatMessage?" + `roomUid=${data.roomUid}`)
// AI-文字-删除对话房间和对话列表数据接口
export const remove = (data) => request.post("/ai/chat/room/remove?" + `roomUid=${data.roomUid}`)
// AI-文字-金豆金额接口文档
export const chargerule = (data) => request.get("/ai/access/chargerule", { params: data })
// AI-文案撰写-发起提问接口
export const sendC1 = (data) => request.post("/ai/chat/send/stream/c1", data)
// AI文字-文案撰写页面-自定义数据字典查询接口
export const dict = (data) => request.get("/user/dict", { params: data })
// AI文字-自定义数据字典保存接口
export const dictSave = (data) => request.post("/user/dict/save", data)
// AI-停止提问接口
export const sendCancel = (data) => request.get("/ai/chat/send/cancel", { params: data })
// 查询充值商品列表接口
export const productList = () => request.get("/product/list")
export const computeRecharge = (data) => request.post("/product/computeRecharge", data)
// 创建订单接口
export const orderCreate = (data) => request.post("/user/order/create", data)
// 创建微信支付二维码接口
export const paymentqrcode = (data) => request.post("/payment/qrcode", data)
// 分页查询订单列表数据
export const orderPage = (data) => request.post("/user/order/page", data)
// 取消未支付的订单接口
export const cancelOrder = (data) => request.post("/user/order/cancel?" + `orderUid=${data}`)
// 分页查询使用记录接口
export const recordPage = (data) => request.post("/user/benefit/record/page", data)
// 根据产品类型查询产品VO数据
export const getProductType = (data) => request.get("/product/getByType?" + `productType=${data.productType}`)
// 查询是否支付接口
export const getPayBillUid = (data) => request.get("/payment/ispay?" + `payBillUid=${data.payBillUid}`)
// 更新用户信息接口-更新用户名
export const updateName = (data) => request.post("/user/update", { nickName: data.nickName })
// 删除订单接口
export const removeOrder = (data) => request.post("/user/order/remove?" + `orderUid=${data}`)
// 咒语-提示词查询接口
export const promptsList = () => request.get("/ai/chat/prompts/list")
// 艺术风格的数据字典值接口
export const listByDrawGroup = (data) => request.get(`/ai/common/listByDrawGroup?groupNo=${data}`)
// 查询AI绘画版本列表接口
export const drawVersions = () => request.get("/ai/draw/versions")
//AI-绘画-消耗金豆数量接口
export const drawChargerule = (data) => request.get('/ai/access/chargerule', { params: data })
//AI-绘画-一键切图列表接口
export const detailView = (data) => request.get(`/ai/draw/detailView?taskUid=${data}`)
// 分页查询AI绘图历史记录接口
export const drawPage = (data) => request.post("/ai/draw/page", data)
// 根据历史图片的详情记录接口
export const drawDetail = (data) => request.get(`/ai/draw/detail?taskUid=${data}`)
// 历史记录-删除draw
export const drawRemove = (data) => request.post(`/ai/draw/remove?drawUid=${data}`)
// 下载图片链接
export const download = (data) => {
  return axios({
    url: `/attachments/file/download?taskUid=${data}`,
    method: 'get',
    responseType: 'blob'
  })
}
// 历史记录-批量删除draw数据
export const drawRemoveBatch = (data) => request.post(`/ai/draw/remove/batch?drawUids=${data}`)
// 上传图片
export const imgUpload = (data) => request.post("/attachments/img/upload", { params: data })
// 发布，分享至社区
export const interactOn = (data) => request.post("/ai/draw/interact/on", data)
// ai绘画批量公开接口
export const interactBatchOn = (data) => request.post(`/ai/draw/interact/batch/on?taskUids=${data}`)
// ai绘画批量取消公开接口
export const interactBatchOff = (data) => request.post(`/ai/draw/interact/batch/off?taskUids=${data}`)
// 取消发布，从社区下架
export const interactOff = (data) => request.post("/ai/draw/interact/off", data)
// 已公开作品的分页接口
export const interactRecordPage = (data) => request.post("/user/interact/record/page", data)


// 查询会员权益比较数据
export const contrast = () => request.get("/ai/access/contrast")

// 个人信息-查询会员已使用情况
export const usage = () => request.get("/user/member/details")
// 根据产品类型查询产品详情接口
export const getByProductType = () => request.get("/user/product/getByType")

// 个人信息-查询会员剩余次数
export const remaining = () => request.get("/user/benefit/remaining")

//  查询邀请奖励-详情接口
export const referralDetail = () => request.get("/user/referral/detail")

// 查询被推荐人列表接口
export const inviteList = (data) => request.post("/user/referral/record/page", data)

// 添加立即提现申请接口
export const withdrawalApply = (data) => request.post("/user/referral/withdrawal/apply", data)
// 查看立即提现已申请的详情接口
export const withdrawalApplyDetail = () => request.get("/user/referral/withdrawal/apply/detail")
// 撤回已申请的理解提现接口
export const withdrawalApplyCancel = (data) => request.post(`/user/referral/withdrawal/apply/cancel?withDrawApplyUid=${data}`)

// 分页查询图生文的数据
export const describePage = (data) => request.post("/ai/draw/describe/page", data)
// 图生文查询详情数据
export const describeDetail = (data) => request.get(`/ai/draw/describe/detail?taskUid=${data}`)
// 图生文批量删除
export const describeRemove = (data) => request.post(`/ai/draw/describe/remove/batch?drawUids=${data}`)


// 分页查询AI换脸的数据
export const facePage = (data) => request.post("/ai/draw/face/page", data)
// AI换脸查询详情数据
export const faceDetail = (data) => request.get(`/ai/draw/face/detail?taskUid=${data}`)
// AI换脸批量删除
export const faceRemove = (data) => request.post(`/ai/draw/face/remove/batch?drawUids=${data}`)

//查询热门功能-消耗金额
export const drawHotChargerule = (data) => request.get(`/ai/access/chargerule/single?benefitFunType=${data}`)

// 首页分页查询图片
export const interactPage = (data) => request.post("/ai/interact/page", data)
// 首页图片详情接口
export const interactDetail = (data) => request.get(`/ai/interact/detail?interactUid=${data}`)
// 首页点赞接口
// export const favorIncrease = (data) => request.post("/ai/interact/detail/favor/increase", data)
export const favorIncrease = (data) => request.post(`/ai/interact/detail/favor/increase?interactUid=${data}`)
// 首页取消点赞接口
// export const favorReduce = (data) => request.post("/ai/interact/detail/favor/reduce", data)
export const favorReduce = (data) => request.post(`/ai/interact/detail/favor/reduce?interactUid=${data}`)

