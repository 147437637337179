<template>
  <div
    id="app"
    v-scrollActive
  >
    <nav-default />
    <router-view />
    <footer-default v-if="$route.path=='/member'" />
    <Out />
  </div>
</template>

<script>
 import NavDefault from  "@/components/NavDefault.vue"
  import FooterDefault from  "@/components/FooterDefault.vue"
  import Out from "@/components/out.vue"
export default {
  name: 'App',
 components: {
  NavDefault, FooterDefault,Out
  },
  data(){
    return {
      cheackLogin:false,
    }
  },
  created () {
  },
  mounted(){
    this.$store.dispatch("CheckLogin");
    this.$store.commit('SET_BAIDUPOPULARIZEVID', window?.baiduPopularizeVid)
  },
  methods: {
  }
}
</script>

<style lang="less">
*{margin: 0;padding:0;}
#app {
  font-family: Source Han Sans CN;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
overflow-y: scroll;
}

.el-dialog{
  border-radius: 32px !important;
  box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06) !important;
}
.el-dialog__body{
  padding:32px 24px !important;
}
.el-dialog__header{
  padding:0px !important;
}

/*提示框的样式 */
.tiv{
min-width: 146px !important;
height: 38px;
border-radius: 8px !important;
opacity: 1;

/* 自动布局 */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 12px;
gap: 8px;
    padding: 8px 12px !important;
    box-sizing: border-box !important;
/* Grey/0 */
background: #FFFFFF !important;

box-sizing: border-box;
/* Grey/1.5 */
border: 1px solid #E6E9F0 !important;

box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.18);
border-radius: 8px !important;

}
.el-message__icon{
  margin-right: 0px !important;
}

/* 滚动条样式 */
::-webkit-scrollbar {
background-color: transparent;
border-radius: 8px;
width: 8px;
}
 
::-webkit-scrollbar-thumb {
background-color: #CACDD9;
border-radius: 8px;
height: 28px;
margin-top: 20px;
}
::-webkit-scrollbar-track {
background-color:transparent;
border-radius: 8px;
}

/* // 这个属性是新出的,文档暂时还查不到,兼容性也不是很好,在需要隐藏滚动条的容器内部使用 */
.scroll-bar-overlay {
  overflow-y: overlay !important;
}
.dom-scrollbar-show::-webkit-scrollbar{
  width: 8px !important;
}
/* .dom-scrollbar-hide::-webkit-scrollbar {
  width: 0 !important;
} */
.dom-scrollbar-hide::-webkit-scrollbar-thumb {
  background-color: transparent !important;
}


/* 下拉框样式 */
.el-select-dropdown{
  border-radius: 12px !important;
opacity: 1;
margin-top: 6px !important;
/* 自动布局 */
 width: 170px;
padding: 6px;
 

/* Grey/0 */
background: #FFFFFF;
border:none !important;
box-shadow: 0px 12px 35px 0px rgba(0, 0, 0, 0.2) !important;

}
.el-select-dropdown .popper__arrow{
  border-style: none !important;
  display: none !important;
}
.el-select-dropdown__item{
  font-size: 14px !important;
font-weight: normal !important;

letter-spacing: 0em !important;
border-radius: 6px;
/* Grey/10 */
color: #1F2126 !important;
padding:0px 20px 0px 44px !important;
}
.el-select-dropdown__item.hover{
  background: #F2F4F7;
  position: relative;
}
.el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
    background: #F2F4F7;
    
}
.el-select-dropdown__list{
  padding:0px;
}
.el-scrollbar__wrap{
  margin-bottom: -14px !important;
}
.el-select-dropdown__item.hover::before{
  position: absolute;
  left:16px;
  top: 0;
  content: '';
  background: url(./static/index/ses.png);
  width: 16px;
  height: 16px;
  background-size:16px 16px;
  top:50%;
  margin-top: -8px;
}


textarea {
font-family: Source Han Sans CN;
} 
.el-dialog {
  display: flex !important;
  flex-direction: column !important;
  margin: 0 !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  overflow-y: scroll !important;
  max-height: 100vh !important;
}
.markdown-body .highlight pre, .markdown-body pre{
  background: #273238 !important;
  
}
.markdown-body{
  font-size: 14px !important;
}
.hljs{
  color:#fff !important
}
 .buttonhover.el-button--primary {
    color: #FFF;
    background-color: #1467FF  ;
    border-color: #1467FF  ;
}

.buttonhover:hover{

/* 按钮/主要 */
box-shadow: 0px 1.5px 0px 0px #E6EAF0;
    background-color: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), linear-gradient(165deg, #2E61FC -36%, #6185FC 185%) !important;
    border-color: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), linear-gradient(165deg, #2E61FC -36%, #6185FC 185%) !important;
  
}
.buttonhover:active{
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(165deg, #2E61FC -36%, #6185FC 185%);
  border-color: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), linear-gradient(165deg, #2E61FC -36%, #6185FC 185%) !important;

/* 按钮/主要 */
box-shadow: 0px 1.5px 0px 0px #E6EAF0;
}


.el-popconfirm{
  .el-button--primary{
    width: 44px;
    height: 24px;
    border-radius: 4px;
    background: #1467FF;
    padding: 1px 8px;
  }
}


.el-message{
  justify-content: center;
  min-width: auto !important;
}
</style>
